
html {
  overflow: scroll;
  overflow-x: hidden;
  /* cursor: none; */
}
::-webkit-scrollbar {
  width: 0px; 
}
.main {
  background-image: url("./Views/Assets/blue.png");
  width: 100%;
  height: max-content;
  background-position: left;
  background-repeat: repeat;
  /* background-color: #fff; */
  /* color:#052FC2;
  color:#1B1B1B;
  color:#F9F8F7; */
}
.container {
  position: relative;
  width: 50%;
  max-width: 300px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.container:hover .overlay {
  opacity: 1;
}
.roadmap {
  background-image: url("./Views/Assets/grid.png");
  width: 100%;
  height: max-content;
  background-repeat: repeat;
  background-position: center;
}
.input {
  background: none;
  border: none;
  border-bottom: 1px solid #0D0D0D;
  padding: 10px;
  color: #0D0D0D;
  width: 80%;
}
.input:focus {
  outline: none !important;
}
@media only screen and (max-width: 1200px) {
  .image {
    width: 100%;
  }
}
