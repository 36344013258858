@import url(https://fonts.googleapis.com/css2?family=Cinzel&family=Heebo&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  overflow: scroll;
  overflow-x: hidden;
  /* cursor: none; */
}
::-webkit-scrollbar {
  width: 0px; 
}
.main {
  background-image: url(/static/media/blue.a79b0cb2.png);
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background-position: left;
  background-repeat: repeat;
  /* background-color: #fff; */
  /* color:#052FC2;
  color:#1B1B1B;
  color:#F9F8F7; */
}
.container {
  position: relative;
  width: 50%;
  max-width: 300px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.container:hover .overlay {
  opacity: 1;
}
.roadmap {
  background-image: url(/static/media/grid.c7a9dedd.png);
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background-repeat: repeat;
  background-position: center;
}
.input {
  background: none;
  border: none;
  border-bottom: 1px solid #0D0D0D;
  padding: 10px;
  color: #0D0D0D;
  width: 80%;
}
.input:focus {
  outline: none !important;
}
@media only screen and (max-width: 1200px) {
  .image {
    width: 100%;
  }
}

